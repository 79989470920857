/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VesselList } from '../fleets.model';

interface FleetListContextState {
  fleetsLoading: boolean;
  listsLoading: boolean;
  completedFetchLists: boolean;
  lists: any[];
  listsError: boolean;
  fleetVesselsLoading: { [listId: string]: boolean };
  refreshFleets: boolean;
}

export const INITIAL_FLEETS_LIST_CONTEXT_STATE: FleetListContextState = {
  fleetsLoading: false,
  listsLoading: false,
  completedFetchLists: false,
  lists: [],
  listsError: false,
  fleetVesselsLoading: {},
  refreshFleets: false,
};

const FleetsListContextSlice = createSlice({
  name: 'fleetsListContext',
  initialState: INITIAL_FLEETS_LIST_CONTEXT_STATE,
  reducers: {
    setFleetsLoading(state, action: PayloadAction<boolean>) {
      state.fleetsLoading = action.payload;
    },
    setListsLoading(state, action: PayloadAction<boolean>) {
      state.listsLoading = action.payload;
    },
    setCompletedFetchLists(state, action: PayloadAction<boolean>) {
      state.completedFetchLists = action.payload;
    },
    setLists(state, action: PayloadAction<VesselList[]>) {
      state.lists = action.payload;
    },
    setListsError(state, action: PayloadAction<boolean>) {
      state.listsError = action.payload;
    },
    setFleetVesselsLoading(
      state,
      action: PayloadAction<{ [listId: string]: boolean }>
    ) {
      state.fleetVesselsLoading = {
        ...state.fleetVesselsLoading,
        ...action.payload,
      };
    },
    setRefreshFleets(state, action: PayloadAction<boolean>) {
      state.refreshFleets = action.payload;
    },
  },
});

export const {
  setFleetsLoading,
  setListsLoading,
  setCompletedFetchLists,
  setLists,
  setListsError,
  setFleetVesselsLoading,
  setRefreshFleets,
} = FleetsListContextSlice.actions;

export default FleetsListContextSlice.reducer;
