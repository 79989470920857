import {
  S3ProviderListOutputItem,
  StorageAccessLevel,
} from '@aws-amplify/storage';
import { EntityShareType } from '../../common-components/upload-entity-documents-modal/upload-entity-documents-validators';

export const serializeS3ProviderListOutputItem = (
  item: S3ProviderListOutputItem
) => ({ ...item, lastModified: item.lastModified?.valueOf()! });

export interface EntityDocument {
  document_id?: string;
  filename: string;
  // ISO 8601 date format
  date_uploaded: string;
  // ISO 8601 date format
  file_date: string;
  description: string;
  s3_location: string;
  entity_id: string;
  entity_type: string;
  file_tenant: string;
  file_company: string;
  // user_id or company_id
  file_owner: string;
  file_owner_enum: FileOwner;
}

export enum EntityType {
  port = 'port',
  country = 'country',
  city = 'city',
  riArea = 'riArea',
  vessel = 'vessel',
}

// Wrapper for Amplify Document Type - allow us to store documents in redux
export interface IDocument
  extends Omit<S3ProviderListOutputItem, 'lastModified'> {
  lastModified: Date | number;
  metadata?: EntityDocument;
}

export interface DocumentWithMeta extends IDocument {
  metadata: EntityDocument;
}

export enum StorageLevel {
  PRIVATE = 'private',
  COMPANY = 'company',
  TENANT = 'tenant',
}

export const StorageDirectory = {
  [StorageLevel.PRIVATE]: 'private/',
  [StorageLevel.COMPANY]: 'company/',
  [StorageLevel.TENANT]: 'tenant/',
};

export const EntityShareTypeToStorageLevel: Record<
  EntityShareType,
  StorageLevel
> = {
  [EntityShareType.NONE]: StorageLevel.PRIVATE,
  [EntityShareType.ORGANISATION]: StorageLevel.COMPANY,
  [EntityShareType.TENANT]: StorageLevel.TENANT,
};

export enum FileOwner {
  PRIVATE = 'user',
  COMPANY = 'company',
  TENANT = 'tenant',
}

export const StorageLevelToFileOwner: Record<StorageLevel, FileOwner> = {
  [StorageLevel.PRIVATE]: FileOwner.PRIVATE,
  [StorageLevel.COMPANY]: FileOwner.COMPANY,
  [StorageLevel.TENANT]: FileOwner.TENANT,
};

export const FileOwnerToStorageLevel: Record<FileOwner, StorageLevel> =
  Object.entries(StorageLevelToFileOwner).reduce(
    (acc, [key, value]) => ({ ...acc, [value]: key as FileOwner }),
    {} as Record<FileOwner, StorageLevel>
  );

export const StorageLevelToAmplifyStorageAccessLevel: Record<
  StorageLevel,
  StorageAccessLevel
> = {
  [StorageLevel.PRIVATE]: 'private',
  [StorageLevel.COMPANY]: 'public',
  [StorageLevel.TENANT]: 'public',
};
