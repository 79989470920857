import { getLastKnownVesselLocations } from '../../../api';
import {
  setSelectedVessels,
  toggleSelectedVessel,
} from '../../../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { useAppDispatch } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { MyFleetVessel } from '../../../maritime-menu-options/my-fleet-panel/myFleetVessels.model';
import { updateVesselInMyFleet } from '../../../state/vessels/vessels.slice';
import store from '../../../store';
import { Vessel } from '../../../models/vessels/vessel.model';
import MapHelpers from '../../map.utils';
import clearSelectedVessel from './clear-selected-vessel';

// utility to function to update fleet vessel location in store
// inside this module due to circular dependency
export const updateMyFleetVesselLocationInStore = async (
  vessel: MyFleetVessel | Vessel
) => {
  const { myFleet } = store.getState().myFleet;

  // We want to update the vessel location in the myfleet store so that the vessel is moved on the map when selected
  // Otherwise, there may be a gap between the myfleet icon and the vessel history icon
  if (vessel.mmsi) {
    const mmsi =
      typeof vessel.mmsi === 'string' ? parseInt(vessel.mmsi, 10) : vessel.mmsi;
    const response = await getLastKnownVesselLocations([mmsi]);

    const longitude =
      response.data[vessel.vessel_id].messages[0].position?.coordinates[0];
    const latitude =
      response.data[vessel.vessel_id].messages[0].position?.coordinates[1];

    const myFleetVessel = myFleet?.byId[vessel.vessel_id];

    if (myFleetVessel && latitude && longitude) {
      const myFleetVesselCopy = {
        ...myFleetVessel,
        latitude,
        longitude,
      };
      store.dispatch(updateVesselInMyFleet(myFleetVesselCopy));
    }
  }
};

const panelVesselOnClick = async (
  e: React.MouseEvent | null,
  vesselItem: Vessel,
  canMultiSelect: boolean,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  updateMyFleetVesselLocationInStore(vesselItem);

  MapHelpers.zoomToPoint(
    { lat: vesselItem.latitude, lng: vesselItem.longitude },
    8
  );

  dispatch(setSelectedDossier(EDossiers.VESSEL));
  if (e && e.ctrlKey && canMultiSelect) {
    dispatch(toggleSelectedVessel(vesselItem));
  } else {
    clearSelectedVessel();

    dispatch(setSelectedVessels([vesselItem]));
  }
};

export default panelVesselOnClick;
