/**
 * Defines temporary / short-lived toggles used to control whether an in-development feature
 * is available in the environment.
 * The values for these are currently expected to come from environment variables configured in
 * the build environment. These can be defined in
 * - local development: local environment/.env files
 * - deployment builds: the amplify configuration (amplify.yml)
 *
 * TODO: One day we'd like these to be more dynamic and sourced from an API instead, so that
 * code changes & releases are not needed to enable a feature once ready & tested.
 */
export type ReleaseToggles = {
  rfDataEnabled: boolean;
  unseenLabsEnabled: boolean;
  globalAisEnabled: boolean;
  mergeStationaryPointsEnabled: boolean;
  avcsEnabled: boolean;
  permissionsTogglesEnabled: boolean;
  adminPermissionsTogglesEnabled: boolean;
};

export const getReleaseToggles = (): ReleaseToggles => ({
  rfDataEnabled: process.env.REACT_APP_RF_DATA_ENABLED === 'true',
  unseenLabsEnabled: process.env.REACT_APP_UNSEENLABS_DATA_ENABLED === 'true',
  globalAisEnabled: process.env.REACT_APP_GLOBAL_AIS_ENABLED === 'true',
  mergeStationaryPointsEnabled:
    process.env.REACT_APP_MERGE_STATIONARY_POINTS_ENABLED === 'true',
  avcsEnabled: process.env.REACT_APP_AVCS_ENABLED === 'true',
  permissionsTogglesEnabled:
    process.env.REACT_APP_PERMISSIONS_TOGGLE_EFFECTS_ENABLED === 'true',

  adminPermissionsTogglesEnabled:
    process.env.REACT_APP_PERMISSIONS_TOGGLE_ADMINISTRATION_ENABLED === 'true',
});
