/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import { HistoryFormValues } from './history-form/history-form-validators';
import HistoryPanelSampleRate from './history-panel-sample-rate';

type IHistoryPanelState = {
  aisDataGapLength: number;
  mergedPointsEnabled: boolean;
  mergedPointsMinimumGapLength: number;
  mergedPointsMinimumCount: number;
  resultsViewOpen: boolean;
  formValues: HistoryFormValues;
};

export const FORMATTED_TODAY = new Date().toISOString().split('T')[0];

export const FORMATTED_ONE_MONTH_AGO = subMonths(new Date(), 1)
  .toISOString()
  .split('T')[0];

export const INITIAL_HISTORY_PANEL_STATE: IHistoryPanelState = {
  aisDataGapLength: 24,
  mergedPointsEnabled: false,
  mergedPointsMinimumGapLength: 1,
  mergedPointsMinimumCount: 1,
  resultsViewOpen: false,
  formValues: {
    identifiers: '',
    toDate: FORMATTED_TODAY,
    fromDate: FORMATTED_ONE_MONTH_AGO,
    sampleRate: HistoryPanelSampleRate.ONE_HOUR,
  },
};

const HistoryPanelSlice = createSlice({
  name: 'historyPanel',
  initialState: INITIAL_HISTORY_PANEL_STATE,
  reducers: {
    setAISDataGapLength: (state, action: PayloadAction<number>) => {
      state.aisDataGapLength = action.payload;
    },
    setMergedPointsMinimumGapLength: (state, action: PayloadAction<number>) => {
      state.mergedPointsMinimumGapLength = action.payload;
    },
    setMergedPointsMinimumCount: (state, action: PayloadAction<number>) => {
      state.mergedPointsMinimumCount = action.payload;
    },
    setMergedPointsEnabled: (state, action: PayloadAction<boolean>) => {
      state.mergedPointsEnabled = action.payload;
    },
    setHistoryFormValues: (
      state,
      action: PayloadAction<Partial<HistoryFormValues> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      };
    },
  },
});

export const {
  setMergedPointsMinimumGapLength,
  setMergedPointsMinimumCount,
  setMergedPointsEnabled,
  setHistoryFormValues,
  setAISDataGapLength,
} = HistoryPanelSlice.actions;

export default HistoryPanelSlice.reducer;
