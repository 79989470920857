/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FleetsFilters,
  FleetVisibility,
  PresetVessel,
  VesselList,
} from './fleets.model';

export const DEFAULT_FLEETS_FILTERS: FleetsFilters = {
  vesselSource: {
    ais: false,
    riskIntelligence: false,
  },
  search: '',
  open: false,
  vesselListsVessels: [],
};

interface PresetValuesState {
  presetVessels: PresetVessel[];
  isCreateFleetsDialogOpen: boolean;
  isCompanyFleet: boolean;
  visibleFleetIds: FleetVisibility[];
  personalFleets: VesselList[];
  companyFleets: VesselList[];
  protectedFleets: string[];
  currentFleetNames: string[];
  fleetsFilters: FleetsFilters;
}

export const INITIAL_FLEETS_PANEL_STATE: PresetValuesState = {
  presetVessels: [],
  isCreateFleetsDialogOpen: false,
  isCompanyFleet: false,
  visibleFleetIds: [],
  personalFleets: [],
  companyFleets: [],
  protectedFleets: [],
  currentFleetNames: [],
  fleetsFilters: DEFAULT_FLEETS_FILTERS,
};

const FleetsPanelSlice = createSlice({
  name: 'fleetsPanel',
  initialState: INITIAL_FLEETS_PANEL_STATE,
  reducers: {
    setFleetsFilters: (
      state,
      action: PayloadAction<Partial<FleetsFilters> | null>
    ) => {
      // Only updates filters explicitly mentioned in action
      state.fleetsFilters = {
        ...state.fleetsFilters,
        ...action.payload,
      };
    },
    setPresetVessels(state, action: PayloadAction<PresetVessel[]>) {
      state.presetVessels = action.payload;
    },
    setIsCreateFleetsDialogOpen(state, action: PayloadAction<boolean>) {
      state.isCreateFleetsDialogOpen = action.payload;
    },
    setIsCompanyFleet(state, action: PayloadAction<boolean>) {
      state.isCompanyFleet = action.payload;
    },
    toggleFleetVisibility: (
      state,
      action: PayloadAction<{ list: VesselList; visible: boolean }>
    ) => {
      const { list, visible } = action.payload;
      const index = state.visibleFleetIds.findIndex(
        (fleet) => fleet.list.list_id === list.list_id
      );

      if (visible) {
        if (index === -1) {
          state.visibleFleetIds.push({ visible, list });
        } else {
          state.visibleFleetIds[index] = { visible, list };
        }
      } else if (index !== -1) {
        state.visibleFleetIds.splice(index, 1);
      }
    },
    removeFleetVisibility: (state, action: PayloadAction<string>) => {
      const fleetIdToDelete = action.payload;

      state.visibleFleetIds = state.visibleFleetIds.filter(
        (fleet) => fleet.list.list_id !== fleetIdToDelete
      );
    },
    setPersonalFleets: (state, action: PayloadAction<VesselList[]>) => {
      state.personalFleets = action.payload;
    },
    setCompanyFleets: (state, action: PayloadAction<VesselList[]>) => {
      state.companyFleets = action.payload;
    },
    protectCompanyFleet: (state) => {
      state.protectedFleets = state.companyFleets.map((fleet) => fleet.list_id);
    },
    updateCurrentFleetNames: (state) => {
      state.currentFleetNames = [
        ...state.companyFleets.map((fleet) => fleet.list_name),
        ...state.personalFleets.map((fleet) => fleet.list_name),
      ];
    },
  },
});

export const {
  setFleetsFilters,
  setPresetVessels,
  setIsCreateFleetsDialogOpen,
  setIsCompanyFleet,
  toggleFleetVisibility,
  removeFleetVisibility,
  setPersonalFleets,
  setCompanyFleets,
  protectCompanyFleet,
  updateCurrentFleetNames,
} = FleetsPanelSlice.actions;

export default FleetsPanelSlice.reducer;
