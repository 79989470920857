export enum FleetStatusMessages {
  ERROR_MESSAGE = 'An Error Occurred',
  SUCCESS_MESSAGE = 'Successful',
  LOADING_MESSAGE = 'Loading...',
  CREATING_FLEET_MESSAGE = 'Creating Fleet',
  ADDING_VESSELS_MESSAGE = 'Adding Vessels to Company Fleet',
  FLEET_CREATED_SUCCESS_MESSAGE = 'Fleet created successfully',
  RETRIEVING_VESSEL_DATA_MESSAGE = 'Retrieving current vessel data...',
  VESSELS_RETRIEVED_SUCCESS_MESSAGE = 'Vessels Retrieved Successfully',
  VERIFYING_FLEET_DATA_MESSAGE = 'Verifying Fleet ',
  ADDING_VESSELS_TO_FLEET_MESSAGE = 'Adding vessels to fleet',
  VESSELS_ADDED_SUCCESS_MESSAGE = 'Vessels added to fleet',
  ADDING_VESSELS_ERROR_MESSAGE = 'Error adding vessels to fleet',
  UPLOAD_SUCCESS_MESSAGE = 'Upload Successful',
  UPLOAD_ERROR_MESSAGE = 'Error Uploading File',
  UPLOADING_MESSAGE = 'File Uploading...',
  NO_VESSELS_MATCHED_MESSAGE = 'No vessels matched the provided data',
}

export default FleetStatusMessages;
