/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetStatusMessages } from '../create-fleet.enum';

interface CreateFleetsState {
  loading: boolean;
  loadingMessage: string;
  success: boolean;
  successMessage: string;
  error: boolean;
  errorMessage: string;
  currentFleets: string[];
  createdFleet: string | null;
}

export const INITAL_CREATE_FLEETS_STATE: CreateFleetsState = {
  loading: false,
  loadingMessage: FleetStatusMessages.LOADING_MESSAGE,
  success: false,
  successMessage: FleetStatusMessages.SUCCESS_MESSAGE,
  error: false,
  errorMessage: FleetStatusMessages.ERROR_MESSAGE,
  currentFleets: [],
  createdFleet: null,
};

const CreateFleetsDialogSlice = createSlice({
  name: 'fleets',
  initialState: INITAL_CREATE_FLEETS_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingMessage: (state, action: PayloadAction<string>) => {
      state.loadingMessage = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setCurrentFleets: (state, action: PayloadAction<string[]>) => {
      state.currentFleets = action.payload;
    },
    setCreatedFleet: (state, action: PayloadAction<string | null>) => {
      state.createdFleet = action.payload;
    },
  },
});

export const {
  setLoading,
  setLoadingMessage,
  setSuccess,
  setSuccessMessage,
  setError,
  setErrorMessage,
  setCurrentFleets,
  setCreatedFleet,
} = CreateFleetsDialogSlice.actions;

export default CreateFleetsDialogSlice.reducer;
