/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Point, Polygon } from '@turf/helpers';
import DateFilters from '../../../common-components/date-filter/date-filter.model';
import { MaritimeAisApiLocationData } from '../../../models/vessels/maritime-ais-api';
import {
  defaultValues,
  HistoricVesselsFormValues,
} from './historic-area-search-form/historic-area-search-form-values';

interface HistoricVesselsState {
  historicVessels: MaritimeAisApiLocationData | null;
  historicVesselsFormValues: HistoricVesselsFormValues;
  historicVesselsPoints: Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]; // TODO this might be wrong
  loading: boolean;
  error: boolean;
  vesselTypeDefaultColouring: boolean;
  visibleMmsis: number[];
}

const initialState: HistoricVesselsState = {
  historicVessels: null,
  historicVesselsFormValues: defaultValues,
  historicVesselsPoints: [],
  loading: false,
  error: false,
  vesselTypeDefaultColouring: false,
  visibleMmsis: [],
};

const historicVesselsSlice = createSlice({
  name: 'historicVessels',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHistoricVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData | null>
    ) => {
      state.historicVessels = action.payload;
      state.loading = false;
      state.error = false;
    },
    setHistoricVesselsFormValues: (
      state,
      action: PayloadAction<Partial<HistoricVesselsFormValues>>
    ) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        ...action.payload,
      };
    },
    setDates: (state, action: PayloadAction<DateFilters>) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        dateTimeRange: action.payload,
      };
    },
    setSearchArea: (
      state,
      action: PayloadAction<Feature<Point | Polygon> | null>
    ) => {
      state.historicVesselsFormValues = {
        ...state.historicVesselsFormValues,
        searchArea: action.payload,
      };
    },
    setHistoricVesselsPoints: (
      state,
      action: PayloadAction<Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]>
    ) => {
      state.historicVesselsPoints = action.payload;
      state.loading = false;
      state.error = false;
    },
    appendHistoricVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData>
    ) => {
      const { data: newData } = action.payload;
      if (newData) {
        return {
          ...state,
          historicVessels: {
            ...(state.historicVessels || {}),
            data: {
              ...(state.historicVessels?.data || {}),
              ...newData,
            },
          },
        };
      }
      return state;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setHistoricVesselsColouring: (state, action: PayloadAction<boolean>) => {
      state.vesselTypeDefaultColouring = action.payload;
    },
    setVisibleMmsis: (state, action: PayloadAction<number>) => {
      const mmsiIndex = state.visibleMmsis.indexOf(action.payload);
      if (mmsiIndex === -1) {
        state.visibleMmsis.push(action.payload);
      } else {
        state.visibleMmsis.splice(mmsiIndex, 1);
      }
    },
    toggleAllVesselsByMmsi: (state, action: PayloadAction<number[] | null>) => {
      if (action.payload) {
        state.visibleMmsis = action.payload;
      } else {
        state.visibleMmsis = [];
      }
    },
  },
});

export const {
  setLoading,
  setHistoricVessels,
  appendHistoricVessels,
  setHistoricVesselsFormValues,
  setHistoricVesselsPoints,
  setError,
  setDates,
  setSearchArea,
  setHistoricVesselsColouring,
  setVisibleMmsis,
  toggleAllVesselsByMmsi,
} = historicVesselsSlice.actions;

export default historicVesselsSlice.reducer;
